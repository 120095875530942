import React from 'react';

const Home = () => {
  return (
    <>
      <h1 className="text-2xl font-bold">Posts</h1>
    </>
  );
};

export default Home;
