import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './home/Home';
import Posts from './posts/Posts';
import Navbar from '../common/Navbar/Navbar';
import Single from './single/Single';

const RouterPages = () => {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/posts/:id" element={<Single />} />
      </Routes>
    </div>
  );
};

export default RouterPages;
